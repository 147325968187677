<template>
  <div class="statistics-home">
      <h1>Statistics</h1>

      <div class="statistics-home-section">
            
        <div class="statistics-home-section-weekly">
            <h3>Real Estate Statistics (last 7 days)</h3>

            <div class="statistics-home-section-weekly-item">
                <p> 
                    Asking price (median) <br/> 
                    <span>$ {{ weeklyReports.median }}</span>
                    <span :class="pricingDeltaClasses(weeklyReports.medianDelta)">{{ weeklyReports.medianDelta }}%</span>
                    <span :class="pricingIconClasses(weeklyReports.medianDelta)"></span>
                </p>
            </div>

            <div class="statistics-home-section-weekly-item">
                <p> 
                    Asking price (average) <br/> 
                    <span>$ {{ weeklyReports.average }}</span>
                    <span :class="pricingDeltaClasses(weeklyReports.averageDelta)">{{ weeklyReports.averageDelta }}%</span>
                    <span :class="pricingIconClasses(weeklyReports.averageDelta)"></span>
                </p>
            </div>

            <div class="statistics-home-section-weekly-item">
                <p> 
                    Total listings <br/> 
                    <span>{{ weeklyReports.total }}</span>
                    <span :class="pricingDeltaClasses(weeklyReports.totalDelta)">{{ weeklyReports.totalDelta }}%</span>
                    <span :class="pricingIconClasses(weeklyReports.totalDelta)"></span>
                </p>
            </div>
        </div>

        <h3>{{ monthlyDistrictLabel() }} per district and change during the last month</h3>
        <div class="statistics-home-section-tools">
            <div class="statistics-home-section-tools-selections">
                <SelectInput
                ref="month"
                placeholder="Select month"
                inputName="month"
                :options="months.choices"
                v-model="months.selected" />

                <SelectInput
                    ref="monthlyStatistic"
                    placeholder="Select metric"
                    inputName="monthlyStatistic"
                    :options="monthlyStatistic.choices"
                    v-model="monthlyStatistic.selected" />
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th>District</th>
                    <th>{{ lastMonth }}</th>
                    <th>{{ currentMonth }}</th>
                    <th>{{ monthlyDistrictDeltaLavel() }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="district in districts" :key="district.name">
                    <td>{{ district.name }}</td>
                    <td>{{ setTableData(district, "past") }}</td>
                    <td>{{ setTableData(district, "current") }}</td>
                    <td :class="pricingDeltaClasses(setTableData(district, 'delta'))">{{ setTableData(district, "delta") }}</td>
                </tr>
            </tbody>
        </table>

      </div>
  </div>
</template>

<script>
import SelectInput from '@/components/form/SelectInput'
import AngelaAPI from '@/utils/AngelaAPI'
import Helpers from '@/utils/Helpers'
export default {
    name: 'StatisticsHome',
    components: {
        SelectInput
    },
    watch: {
        'months.selected': function (val) {
            if(!this.fetchingData){
                // TODO: HARDCODED YEAR, CHANGE ASAP!
                this.getDistrictsMonthlyData(`2020-${val}-01`);
            }
        }
    },
    data(){
        return {
            fetchingData: false,
            weeklyReports: {
                week: 0,
                median: '',
                average: '',
                total: '',
                medianDelta: 0,
                averageDelta: 0,
                tptalDelta: 0
            },
            months: {
                choices: [
                    {name: 'January', val: '01'},
                    {name: 'February', val: '02'},
                    {name: 'March', val: '03'},
                    {name: 'April', val: '04'},
                    {name: 'May', val: '05'},
                    {name: 'June', val: '06'},
                    {name: 'July', val: '07'},
                    {name: 'August', val: '08'},
                    {name: 'September', val: '09'},
                    {name: 'October', val: '10'},
                    {name: 'November', val: '11'},
                    {name: 'December', val: '12'}
                ],
                selected: ''
            },
            monthlyStatistic: {
                choices: [
                    {name: 'Median', val: 'median'},
                    {name: 'Average', val: 'average'},
                    {name: 'Total', val: 'total'}
                ],
                selected: 'median'
            },
            currentMonth: '',
            lastMonth: '',
            districts: []
        };
    },
    mounted(){
        this.$refs.monthlyStatistic.setValue('median');
        this.getDistrictsMonthlyData('');
        this.getWeeklyData();
    },
    methods: {
        getDistrictsMonthlyData(date){
            this.fetchingData = true;
            AngelaAPI.getDistrictsMonthlyData(date).then((response) => {
                // Get moment dates
                const currentMomentMonth = response.data.data.current_month
                const lastMomentMonth = response.data.data.last_month
                
                // Set data values
                this.months.selected = currentMomentMonth.month
                
                // Set input values
                this.$refs.month.setValue(this.months.selected);

                // Set table values
                this.currentMonth = currentMomentMonth.formatted
                this.lastMonth = lastMomentMonth.formatted

                this.districts = response.data.data.districts
                
                setTimeout(() => {
                    this.fetchingData = false;
                }, 1000)
                
            })
            .catch((error) => {
                this.fetchingData = false;
                // console.log(error);
            });
        },
        getWeeklyData(){
            AngelaAPI.getWeeklyData().then((response) => {
                // Week
                this.weeklyReports.week = response.data.data.week_number;
                // Total
                this.weeklyReports.total = this.formatPrice(response.data.data.amount_of_listings.current_week);
                this.weeklyReports.totalDelta = response.data.data.amount_of_listings.delta;
                // Average
                this.weeklyReports.average = this.formatPrice(response.data.data.average_asking_price.current_week);
                this.weeklyReports.averageDelta = response.data.data.average_asking_price.delta;
                // Median
                this.weeklyReports.median = this.formatPrice(response.data.data.median_asking_price.current_week);
                this.weeklyReports.medianDelta = response.data.data.median_asking_price.delta;
            })
            .catch((error) => {
                // console.log(error);
            });
        },
        monthlyDistrictLabel(){
            switch(this.monthlyStatistic.selected){
                case 'median': 
                    return 'Median Asking Price($)';
                case 'average': 
                    return 'Average Asking Price($)';
                case 'total': 
                    return 'Total listings';
            }
        },
        monthlyDistrictDeltaLavel(){
            switch(this.monthlyStatistic.selected){
                case 'total': 
                    return 'Added / Removed';
                default:
                    return 'Delta(%)'
            }
        },
        setTableData(district, type){
            const metric = this.monthlyStatistic.selected;
            switch(type){
                case 'current': 
                    return this.formatPrice(district.current_month[metric]);
                case 'past': 
                    return this.formatPrice(district.last_month[metric]);
                case 'delta': 
                    if(metric === 'total'){
                        return district.delta[metric];
                    }else{
                        return district.delta[metric].toFixed(1);
                    }
            }
        },
        formatPrice(price){
            return Helpers.addCommasToNumber(price);
        },
        pricingIconClasses(delta){
            return {
                'pricing-icon': true,
                'pricing-icon-current': (delta === 0),
                'pricing-icon-up': (delta > 0),
                'pricing-icon-down': (delta < 0)
            };
        },
        pricingDeltaClasses(delta){
            return {
                'pricing-delta': true,
                'pricing-delta-current': (delta === 0),
                'pricing-delta-up': (delta > 0),
                'pricing-delta-down': (delta < 0)
            };
        }
    }
}
</script>

<style lang="scss">
.statistics-home{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 25px;

    h1{
        font-size: 1.125rem;
        font-weight: 600;
    }

    h3{
        margin-top: 25px;
        margin-bottom: 15px;
        font-weight: 600;
    }

    &-section{

        margin-top: 15px;

        table{
            width: 100%;
            display: block;
            empty-cells: show;
            margin-top: 15px;

            thead, tbody{
                display: block;
                width: 100%;
            }

            thead{
                color: #979797;
                font-size: 0.625rem;
                font-weight: 300;
                tr{
                    th{
                        padding-right: 0px;
                    }
                }
            }

            tbody{
                font-size: 0.675rem;
                tr{
                    border-bottom: 1px solid #D8D8D8;
                    td{
                        padding: 10px 0px;
                    }
                }
            }

            tr{
                display: flex;

                th, td{
                    flex-basis: 100%;
                    text-align: right;
                    padding: 5px;
                }
            }
        }

        &-weekly{

            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            
            h3{
                width: 100%;
            }

            &-item{
                margin-top: 5px;
                margin-right: 25px;
                padding-right: 25px;
                border-right: 1px solid black;
                position: relative;

                &:last-child{
                    border: 0px;
                }

                p{
                    span{
                        display: inline-block;
                        margin-top: 10px;
                        font-weight: 600;
                    }
                }
            }
        }

        &-tools{
            h3{
                display: block;
                width: 100%;
            }

             &-selections{
                 display: flex;
                 .form-select-input{
                     margin: 5px;
                 }
             }

        }
    }

    .pricing-icon{
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      background-repeat: no-repeat;
      background-position: bottom bottom;
      background-color: rgba(0, 0, 0, 0);
      margin-left: 5px;
      position: absolute;
      top: 19px;
      &-current{
        opacity: 0px;
      }
      &-up{
        background-image: url(../../../assets/images/icons/positive_up_arrow.svg);
      }
      &-down{
        background-image: url(../../../assets/images/icons/negative_down_arrow.svg);
      }
    }

    .pricing-delta{
        font-weight: 100;
        margin-left: 10px;
        &-up{ color: #50AE22; }
        &-down{ color: #ff1e1e; }
    }
}

</style>
